import React, {FC} from "react";
import {Col, Row} from "reactstrap";
import {Parallax} from "react-scroll-parallax";
import {strings, valueDeviceAsNumber, valueDeviceAsString} from "../../utils";

export const VanityRow: FC = () => {
    return <Row className="row-grid justify-content-between align-items-center text-left"
                style={{height: valueDeviceAsString("calc(100vh - 50px)", "calc(100vh)", "calc(125vh)")}}>
        <Col lg="6" md="6" sm="12" className="mobile-parallax-padding" style={{paddingTop: valueDeviceAsString("20px", "40px", "20px")}}>
            <Parallax speed={15} opacity={[1, 0, "easeInBack"]}>
                <div>
                    <p className="mb-3">
                        {strings.title1}
                    </p>
                    <h1 className="vanity">
                        MALIN A KAASTAD <span>{strings.and}</span> JAMES TOGNOLA
                    </h1>
                    <p className="mb-3">
                        {strings.intro1}
                        {strings.intro2}
                    </p>
                    <div className="pretend-button"
                         onClick={() => window.scrollTo({top: window.innerHeight})}>
                        <p className="category d-inline">
                            {strings.rsvp}
                        </p>
                        <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-arrow-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd"
                                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                    </svg>
                                </span>
                    </div>
                </div>
            </Parallax>
        </Col>
        <Col lg="4" md="5" sm="12" className="mobile-parallax-padding vanity-image" style={{marginTop: `${valueDeviceAsNumber(0, 0, 50)}px`}}>
            <Parallax speed={valueDeviceAsNumber(-15, -10)} opacity={[1, 0, "easeInBack"]}>
                <div className={"border-wrapper"}>
                    <Parallax speed={5} translateY={[valueDeviceAsNumber(-10, 3), 10, "easeInOutSine"]}>
                        <img
                            alt="..."
                            className="img-fluid"
                            src={require("../../assets/images/vanity.jpg")}
                        />
                    </Parallax>
                </div>
            </Parallax>
        </Col>
    </Row>;
};