import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
    en: {
        title1:"Together with their families",
        and: "and",
        intro1: "Request the pleasure of your company to celebrate their marriage at St Robert's Catholic Church, Harrogate on ",
        intro2: "Friday 27th of September 2024 at 1 o'clock with reception to follow at Rudding Park",
        rsvp: "RSVP",
        church: "Church",
        maps: "Maps",
        reception: "Reception",
        accommodation: "Accommodation",
        show_alternatives: "Show Alternatives",
        alternative_hotels: "Alternative Hotels",
        alternative_hotels_text: "If you are booking a hotel and would prefer to be away from the reception, here are a just some nearby hotels in Harrogate.",
        from_location: "from reception",
        see_here: "View",
        children: "We know that some of you will be traveling with your kids. If you need a babysitter during the day/evening, please let us know by 1 June. For further information please contact one of us.",
    },
    no: {
        title1:"Med sine familier ønsker",
        and: "og",
        intro1: "Hjertelig velkommen til bryllup i St Robert's Catholic Church i Harrogate ",
        intro2: "Fredag 27 September 2024 klokken 13:00 bryllupsfesten vil finne sted pa Rudding Park",
        rsvp: "SVARFRIST",
        church: "Kirke",
        maps: "Kart",
        reception: "Bryllupslokale",
        accommodation: "Overnatting",
        show_alternatives: "Vis alternative overnattingssteder",
        alternative_hotels: "Alternative Hotell",
        alternative_hotels_text: "Dersom du ønsker å booke hotell tilbaketrukket fra bryllupslokasjonen, har vi funnet noen alternativer i Harrogate nedenfor",
        from_location: "fra lokalet",
        see_here: "Se her",
        children: "Vi vet at noen av dere reiser med småbarn, og dersom dere trenger barnepass på dagen/kvelden kan vi selvfølgelig hjelpe med dette! Vennligst si ifra innen 1 juni om dette ønskes.",
    },
    nb: {
        title1:"Med sine familier ønsker",
        and: "og",
        intro1: "Hjertelig velkommen til bryllup i St Robert's Catholic Church i Harrogate ",
        intro2: "Fredag 27 September 2024 klokken 13:00 bryllupsfesten vil finne sted pa Rudding Park",
        rsvp: "SVARFRIST",
        church: "Kirke",
        maps: "Kart",
        reception: "Bryllupslokale",
        accommodation: "Overnatting",
        show_alternatives: "Vis alternative overnattingssteder",
        alternative_hotels: "Alternative Hotell",
        alternative_hotels_text: "Dersom du ønsker å booke hotell tilbaketrukket fra bryllupslokasjonen, har vi funnet noen alternativer i Harrogate nedenfor",
        from_location: "fra lokalet",
        see_here: "Se her",
        children: "Vi vet at noen av dere reiser med småbarn, og dersom dere trenger barnepass på dagen/kvelden kan vi selvfølgelig hjelpe med dette! Vennligst si ifra innen 1 juni om dette ønskes.",
    },
    nn: {
        title1:"Med sine familier ønsker",
        and: "og",
        intro1: "Hjertelig velkommen til bryllup i St Robert's Catholic Church i Harrogate ",
        intro2: "Fredag 27 September 2024 klokken 13:00 bryllupsfesten vil finne sted pa Rudding Park",
        rsvp: "SVARFRIST",
        church: "Kirke",
        maps: "Kart",
        reception: "Bryllupslokale",
        accommodation: "Overnatting",
        show_alternatives: "Vis alternative overnattingssteder",
        alternative_hotels: "Alternative Hotell",
        alternative_hotels_text: "Dersom du ønsker å booke hotell tilbaketrukket fra bryllupslokasjonen, har vi funnet noen alternativer i Harrogate nedenfor",
        from_location: "fra lokalet",
        see_here: "Se her",
        children: "Vi vet at noen av dere reiser med småbarn, og dersom dere trenger barnepass på dagen/kvelden kan vi selvfølgelig hjelpe med dette! Vennligst si ifra innen 1 juni om dette ønskes.",
    }
});