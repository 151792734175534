import React, {FC} from "react";
import {Col, Row} from "reactstrap";
import {Parallax} from "react-scroll-parallax";
import Countdown from "../../components/Countdown/Countdown";

export const CountdownRow: FC = () => {
    return <Row className="row-grid justify-content-between align-items-center text-left"
                style={{height: "calc(100vh - 160px)"}}>
        <Col md={{offset: 3, size: 6}} lg={{offset: 3, size: 6}}>
            <Parallax speed={15}>
                <div style={{transform: "translateY(-20%)"}}>
                    <h2 className="vanity">
                        <Countdown targetDate={"2024/09/27 13:00:00"}/>
                    </h2>
                </div>
            </Parallax>
        </Col>
    </Row>
};