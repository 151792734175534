import React, {FC} from "react";
import {Col, Row} from "reactstrap";
import {Parallax} from "react-scroll-parallax";
import {strings} from "../../utils";
import {PretendButton} from "../../components/PretendButton/PretendButton";
const hotels: {
    image: any,
    name: string,
    miles: string,
    url: string,
}[] = [
    {
        name: "Rudding Park",
        image: require("../../assets/images/hilton.jpg"),
        url: "https://www.ruddingpark.co.uk/",
        miles: "0"
    },
    {
        name: "The Grafton",
        image: require("../../assets/images/hilton.jpg"),
        url: "https://www.graftonhotel.co.uk/",
        miles: "3.4"
    },
    {
        name: "Crowne Plaza Harrogate",
        image: require("../../assets/images/hilton.jpg"),
        url: "https://www.ihg.com/crowneplaza/hotels/gb/en/harrogate/hrtuk/hoteldetail",
        miles: "3.5"
    },
    {
        name: "Double Tree by Hilton",
        image: require("../../assets/images/hilton.jpg"),
        url: "https://www.hilton.com/en/hotels/lbahadi-doubletree-harrogate-majestic-hotel-and-spa/",
        miles: "3.8"
    }
];

export const AlternativeHotelsRows: FC = () => {
    return <Row className="row-grid justify-content-between align-items-start text-left"
                style={window.innerWidth >= 767 ? {height: "calc(100vh - 160px)"} : {height: "auto"}}>
        <Col md={12} style={window.innerWidth >= 767 ? {} : {paddingBottom: "20px"}}>
            <Parallax speed={window.innerWidth >= 767 ? 5 : -20} translateY={["0px", window.innerWidth >= 767 ? "50px" : "10px", "easeOut"]}>
                <h2>{strings.alternative_hotels}</h2>
                <p>
                    {strings.alternative_hotels_text}
                </p>
            </Parallax>
        </Col>

        {hotels.map((hotel, i) =>
            <Col key={i} md={6} lg={3} style={window.innerWidth >= 767 ? {} : {paddingTop: "50px", paddingBottom: "25px"}}>
                <Parallax speed={window.innerWidth >= 767 ? (i % 2 ? -5 : 5) : -5}>
                    <div className={"border-wrapper standard"}>
                        <img alt="Rudding Park" src={hotel.image}/>
                        <h3 className="vanity">
                            {hotel.name}
                        </h3>

                        <p>
                            {hotel.miles} miles {strings.from_location}
                        </p>

                        <PretendButton
                            text={strings.see_here || ""}
                            onClick={() => window.open(hotel.url, '_blank')}
                        />
                    </div>
                </Parallax>
            </Col>
        )}
    </Row>;
};