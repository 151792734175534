import React, { FC } from "react";
import { Col, Row } from "reactstrap";
import { Parallax } from "react-scroll-parallax";
import {valueDeviceAsNumber, valueDeviceAsString} from "../../utils";

export const RegistryRow: FC = () => {
    return <Row className="row-grid justify-content-between align-items-center text-left" style={{height: `calc(100vh ${valueDeviceAsString("- 160px", "+ 50vh")})`}}>
        <Col md={{offset: 3, size: 6}} lg={{offset: 3, size: 6}}>
            <Parallax speed={valueDeviceAsNumber(15, 10)}>
                <div style={{transform: `translateY(${valueDeviceAsString("-20%", "50vh")})`}}>
                    <h2 className="vanity">
                        Registry
                    </h2>
                    <p>
                        Your presence at our wedding is gift enough and we really appreciate you coming out to celebrate with us! But should you wish to give a gift as well, a cash gift to put towards our honeymoon would be greatly appreciated.
                    </p>
                </div>
            </Parallax>
        </Col>
    </Row>
};