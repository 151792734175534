import React, {FC} from "react";
import {Col, Row} from "reactstrap";
import {Parallax} from "react-scroll-parallax";
import {Form} from "../../components/Form/Form";
import {strings, valueDeviceAsString} from "../../utils";
import {PretendButton} from "../../components/PretendButton/PretendButton";

export const RsvpRow: FC = () => {
    return <Row className="row-grid justify-content-between align-items-center text-left"
                style={window.innerWidth >= 767 ? {height: "calc(100vh - 160px)"} : {height: "calc(150vh + 650px)", paddingTop: "350px"}}>
        <Col lg="6" md="6" sm="12" className="mobile-parallax-padding" style={window.innerWidth >= 767 ? {} : { paddingBottom: "250px" }}>
            <Parallax speed={-20}>
                <Form />
            </Parallax>
        </Col>

        <Col lg="4" md="6" sm="12" className="mobile-parallax-padding" style={{paddingTop: valueDeviceAsString("0", "150px"), paddingBottom: valueDeviceAsString("0", "0")}}>
            <Parallax speed={10} translateY={["-50px", "0px", "easeInOutSine"]}>
                <div>
                    <h1 className="vanity">
                        {strings.church}
                    </h1>
                    <p className="mb-3">
                        Our Lady Immaculate & St Robert Church
                        <hr/>
                        3 Robert St, Harrogate HG1 1HP, United Kingdom
                    </p>
                    <PretendButton
                        text={strings.maps || ""}
                        onClick={() => window.open("https://maps.app.goo.gl/VsqMztD1dX6saCqWA",'_blank')}
                    />
                </div>

                <br/>

                <div>
                    <h1 className="vanity">
                        {strings.reception}
                    </h1>
                    <p className="mb-3">
                        Rudding Park
                        <hr/>
                        Rudding Park Hotel, Follifoot, Harrogate, North Yorkshire HG3 1JH
                    </p>
                    <PretendButton
                        text={strings.maps || ""}
                        onClick={() => window.open("https://maps.app.goo.gl/Dq1otmeRjunYzEcy5",'_blank')}
                    />
                </div>

                <br/>

                <div>
                    <h1 className="vanity">
                        {strings.accommodation}
                    </h1>
                    <p className="mb-3">
                        Rudding Park
                        <hr/>
                        Rudding Park Hotel, Follifoot, Harrogate, North Yorkshire HG3 1JH
                    </p>
                    <PretendButton
                        text={strings.show_alternatives || ""}
                        onClick={() => window.scrollTo({top: (window.innerHeight * 3) + 55})}
                    />
                </div>
            </Parallax>
        </Col>
    </Row>;
};